<template>
  <div class="Echarts">
    <div id="main" style="width: 100%;height:400px;"></div>
  </div>
</template>

<script>
export default {
  name: "Echarts",
  props: {
    chartPVData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    chartUVData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    chartIPData: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data () {
    return {
      myChart: ""
    }
  },
  watch: {
    chartPVData (val) {
      if (val != []) {
        //刷新数据
        var option = this.myChart.getOption();
        option.series[0].data = val;
        option.series[1].data = this.chartUVData;
        option.series[2].data = this.chartIPData;
        this.myChart.setOption(option);
      }
    }
  },
  methods: {
    myEcharts () {
      // 基于准备好的dom，初始化echarts实例
      this.myChart = this.$echarts.init(document.getElementById("main"));

      // 指定图表的配置项和数据
      var option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['浏览量', '访客量', 'IP数']
        },
        xAxis: {
          data: [
            "0点",
            "1点",
            "2点",
            "3点",
            "4点",
            "5点",
            "6点",
            "7点",
            "8点",
            "9点",
            "10点",
            "11点",
            "12点",
            "13点",
            "14点",
            "15点",
            "16点",
            "17点",
            "18点",
            "19点",
            "20点",
            "21点",
            "22点",
            "23点",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            type: "line",
            smooth: true,
            name: "浏览量",
            data: [],
          },
          {
            type: "line",
            smooth: true,
            name: "访客量",
            data: [],
          },
          {
            type: "line",
            smooth: true,
            name: "IP数",
            data: [],
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      this.myChart.setOption(option);
    },
  },
  mounted () {
    this.myEcharts();
  },
};
</script>

<style></style>
