<template>
  <div class="statistics">
    <div class="statistics_content">
      <div class="statistics_label flex">
        <el-date-picker
          style="width: 11rem;margin-right: 20px"
          v-model="search"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          @change="getData"
        ></el-date-picker>
      </div>
      <el-divider></el-divider>
      <el-row :gutter="24">
        <el-col :span="4" v-for="(item, index) in baiduData" :key="index">
          <el-card class="statistics_data-item" shadow="always">
            <el-popover placement="top-start" width="200" trigger="hover" :content="item.title">
              <span slot="reference">{{ item.title }}</span>
            </el-popover>
            <p>{{ item.value }}</p>
          </el-card>
        </el-col>
      </el-row>
      <div class="statistics_echarts">
        <div class="statistics_echarts-title flex">
          <p>趋势图</p>
          <!-- <el-radio-group v-model="radio" @change="getInfoList">
            <el-radio-button label="pv_count">浏览量</el-radio-button>
            <el-radio-button label="visitor_count">访问量</el-radio-button>
            <el-radio-button label="ip_count">I P 数</el-radio-button>
          </el-radio-group>-->
          <el-date-picker
            v-model="searchChart"
            type="date"
            placeholder="选择日期"
            value-format="yyyyMMdd"
            style="margin: 0 20px"
            @change="getInfoList"
          ></el-date-picker>
        </div>
        <Echarts :chartPVData="chartPVData" :chartUVData="chartUVData" :chartIPData="chartIPData" />
      </div>
      <div class="statistics_visitor">
        <div class="statistics_visitor-title flex">
          <p class="font-big">访问明细</p>
          <p class="font-small">
            小贴士：访问时长显示未知的原因:当用户快速关闭浏览器、长时间未对页面进行操作或出现网络问题时，系统会无法获取到页面的关闭信息，到时页面的访问时长无法计算。
          </p>
        </div>
        <Visitor />
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from "@/components/Echarts.vue"
import Visitor from "./Visitor.vue"
import api from "@/api/baidu.js"
import utils from "@/utils/utils.js"
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime())
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近七天",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit("pick", [start, end])
            },
          },
          {
            text: "最近三十天",
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit("pick", [start, end])
            },
          },
        ],
      },
      // 筛选条件
      search: [new Date(), new Date()],
      searchChart: new Date(),
      baiduData: [
        { title: "浏览量（PV）", id: "pv_count", value: "" },
        { title: "访客数（UV）", id: "visitor_count", value: "" },
        { title: "IP数", id: "ip_count", value: "" },
        { title: "平均访问时长", id: "avg_visit_time", value: "" },
        { title: "跳出率", id: "bounce_ratio", value: "" },
        { title: "平均访问深度", id: "avg_visit_pages", value: "" },
      ],
      radio: "pv_count",
      // 趋势图数据
      chartPVData: [],
      chartUVData: [],
      chartIPData: [],
    }
  },
  methods: {
    // 获取数据
    async getData() {
      // metrics的顺序比较重要
      var condition = {
        userId: JSON.parse(localStorage.getItem("userInfo")).userId,
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        body: {
          site_id: JSON.parse(localStorage.getItem("userInfo")).siteId,
          start_date: utils.format(this.search[0], "yyyyMMdd"),
          end_date: utils.format(this.search[1], "yyyyMMdd"),
          metrics: "pv_count,visitor_count,ip_count,avg_visit_time,bounce_ratio,avg_visit_pages",
          method: "trend/time/a",
          gran: "day",
          area: "china",
          start_date2: utils.format(this.search[0], "yyyyMMdd"),
        },
      }
      try {
        const res = await api.getData(condition)
        if (res.data.code == 200 && (res.data.result || []).length > 0) {
          let list = res.data.result[0].result
          let sum = list.sum.length > 0 ? list.sum[0] : []
          this.baiduData.forEach((item, index) => {
            if (item.id == "avg_visit_time") {
              item.value = utils.formatSecToDate(sum[index])
            } else {
              item.value = sum[index]
            }
          })
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
    // 获取网站概况数据（用于图表）
    async getInfoList() {
      var condition = {
        userId: JSON.parse(localStorage.getItem("userInfo")).userId,
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        body: {
          site_id: JSON.parse(localStorage.getItem("userInfo")).siteId,
          start_date: this.searchChart,
          end_date: this.searchChart,
          metrics: "pv_count,visitor_count,ip_count",
          method: "overview/getTimeTrendRpt",
        },
      }
      try {
        const res = await api.getData(condition)
        if (res.data.code == 200 && (res.data.result || []).length > 0) {
          let list = res.data.result[0].result
          if ((list.items || []).length > 0) {
            let infoList1 = [],
              infoList2 = [],
              infoList3 = []
            list.items[1].forEach((item) => {
              infoList1.push(item[0] != "--" ? item[0] : 0)
              infoList2.push(item[1] != "--" ? item[1] : 0)
              infoList3.push(item[2] != "--" ? item[2] : 0)
            })
            this.chartPVData = infoList1
            this.chartUVData = infoList2
            this.chartIPData = infoList3
          }
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
  components: {
    Echarts,
    Visitor,
  },
  mounted() {
    this.getData()
    this.getInfoList()
  },
}
</script>

<style lang="scss">
$fontColor: #333333;
$fontShallow: #cccccc;
$bgColor: #ffffff;

.statistics {
  padding: 10px;
  &_content {
    background-color: $bgColor;
    border-radius: 10px;
    padding: 20px;
    color: $fontColor;
    margin-bottom: 20px;
  }

  &_label {
    align-items: center;
  }

  &_echarts {
    margin-top: 60px;

    .statistics_echarts-title {
      align-items: center;

      > p {
        font-weight: bold;
        margin-right: 20px;
        font-size: 18px;
      }
    }
  }

  &_visitor {
    margin-top: 60px;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
    padding: 20px;

    .statistics_visitor-title {
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .font-big {
        font-weight: bold;
        margin-right: 20px;
        font-size: 18px;
      }

      .font-small {
        color: $fontShallow;
      }
    }
  }

  .el-card__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      letter-spacing: 0px;
      color: #23a2ef;
      font-size: 24px;
      margin-top: 10px;
      font-weight: bold;
    }
  }
}
</style>
