import request from "@/utils/request.js"
export default {
  // 获取百度统计-趋势分析
  getData(obj) {
    return request({
      url: "/thirdParty/getBaiduData",
      method: "post",
      data: obj,
    })
  },
}
