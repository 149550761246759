<template>
  <div class="visitor">
    <el-table
      :data="tableData"
      stripe
      style="width: 100%;border: 1px solid #e8e8e8"
      :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
    >
      <el-table-column label="访问时间">
        <template slot-scope="scope" fixed>
          <el-popover
            placement="top-start"
            width="200"
            trigger="hover"
            :content="scope.row.start_time"
          >
            <p class="table_font" slot="reference">{{ scope.row.start_time }}</p>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型"></el-table-column>
      <el-table-column prop="area" label="城市"></el-table-column>
      <el-table-column prop="source" label="来源"></el-table-column>
      <el-table-column prop="access_page" label="入口页面"></el-table-column>
      <el-table-column prop="ip" label="访问IP"></el-table-column>
      <el-table-column prop="visit_time" label="访问时长"></el-table-column>
      <el-table-column prop="visit_pages" label="访问深度"></el-table-column>
    </el-table>
    <el-pagination
      class="pagination flex"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :page-sizes="[5, 10, 15, 20]"
      :page-size="rows"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    ></el-pagination>
  </div>
</template>

<script>
import api from "@/api/baidu.js"
export default {
  data() {
    return {
      tableData: [
        {
          area: "",
          source: "直接访问",
          access_page: "http://513dvr.com/",
          ip: "116.89.139.153",
          visit_time: "00:01:57",
          visit_pages: "3",
          start_time: "2021/01/26 10:20:13",
        },
      ],
      rows: 5,
      page: 1,
      total: 0,
    }
  },
  methods: {
    // 分页
    handleSizeChange(val) {
      this.rows = val
      this.getVisitor()
    },
    handleCurrentChange(val) {
      this.page = val
      this.getVisitor()
    },
    // 实时访客
    async getVisitor() {
      // metrics的顺序比较重要
      var condition = {
        userId: JSON.parse(localStorage.getItem("userInfo")).userId,
        companyId: JSON.parse(localStorage.getItem("userInfo")).companyId,
        body: {
          site_id: JSON.parse(localStorage.getItem("userInfo")).siteId,
          metrics: "start_time,area,source,access_page,ip,visit_time,visit_pages",
          order: "e.g:lastVisitTime,desc",
          method: "trend/latest/a",
          start_index: (this.page - 1) * this.rows,
          max_results: this.rows,
          area: "china",
        },
      }
      try {
        const res = await api.getData(condition)
        if (res.data.code == 200 && (res.data.result || []).length > 0) {
          let list = res.data.result[0].result
          if ((list.items || []).length > 0) {
            this.total = list.total
            // 访客详情数据
            let infoList = list.items.length > 0 ? list.items[0] : [],
              tableData = []
            list.items[1].forEach((item, index) => {
              tableData.push({
                start_time: item[0],
                area: item[1],
                source: item[2].fromType,
                access_page: item[3],
                ip: item[4],
                visit_time: item[5],
                visit_pages: item[6],
                type: infoList[index][0].detail.visitorType,
              })
            })
            this.tableData = tableData
          }
        } else {
          this.$message.error(res.data.message)
        }
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
  mounted() {
    this.getVisitor()
  },
}
</script>

<style>
.pagination {
  justify-content: flex-end;
  margin-top: 20px;
}
</style>
